import type { ReactNode } from "react";
import { type Control, Controller, type FieldPath, type FieldValues } from "react-hook-form";
import { controllerProps, formPropsToAriaProps } from "../../components/forms/ariaController.tsx";
import { FormField } from "../../components/forms/FormField.tsx";
import { WCheckbox } from "../../components/forms/WCheckbox.tsx";
import { WTextField } from "../../components/forms/WTextField.tsx";
import type { FieldConfig } from "../AdminTable.tsx";
import { AInput } from "./Fields.tsx";

export function AFTextField<T extends FieldValues>({
    control,
    isRequired,
    name,
    config,
    nullable,
    children = <AInput />,
}: {
    control: Control<T>;
    name: FieldPath<T>;
    config?: FieldConfig<any, string> | FieldConfig<any, string | undefined | null>;
    isRequired?: boolean;
    nullable?: boolean;
    children?: ReactNode;
}) {
    return (
        <Controller
            control={control}
            name={name}
            {...controllerProps({ isRequired })}
            render={(f) => (
                <WTextField
                    wide
                    isRequired={isRequired}
                    label={getEditTitle(config, name)}
                    {...formPropsToAriaProps(f)}
                    value={f.field.value ?? ""}
                    onChange={(v) => f.field.onChange(v === "" && nullable ? null : v)}
                >
                    {children}
                </WTextField>
            )}
        />
    );
}

function getEditTitle(config: FieldConfig<any, any> | undefined, name: string): ReactNode {
    return config?.editTitle ?? config?.title ?? name;
}

export function AFCheckbox<T extends FieldValues>({
    config,
    control,
    name,
}: {
    control: Control<T>;
    name: FieldPath<T>;
    config?: FieldConfig<any, boolean>;
}) {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <FormField wide label={getEditTitle(config, name)}>
                    <WCheckbox isSelected={field.value} onChange={(v) => field.onChange(v)} />
                </FormField>
            )}
        />
    );
}
