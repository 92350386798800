import { capitalize, mapValues } from "remeda";

//#region Types

/** Properties that are set automatically by default (but can be overridden) */
interface EntityMetaDerived {
    viewTitle: string;
    addLabel: string;
    editLabel: string;
}

/** Properties that have to be declared for every entity */
interface EntityMetaDeclared {
    name: string;
    plural: string;
}

/** All properties of an entity */
export interface EntityMeta extends EntityMetaDeclared, EntityMetaDerived {}

//#endregion

//#region Configuration

type EntityMetaConfig = EntityMetaDeclared & Partial<EntityMetaDerived>;

const metaConfig = {
    machine: {
        name: "machine",
        plural: "machines",
    },
    lease: {
        name: "lease",
        plural: "leases",
    },
    spec: {
        name: "spec",
        plural: "specs",
    },
    os: {
        name: "operating system",
        plural: "operating systems",
    },
    os_type: {
        name: "operating system type",
        plural: "operating system types",
    },
} satisfies Record<string, EntityMetaConfig>;

export type EntityType = keyof typeof metaConfig;

//#endregion

//#region Automatically derived properties
function makeFullMeta(m: EntityMetaConfig): EntityMeta {
    return {
        viewTitle: capitalize(m.plural),
        addLabel: `Add ${capitalize(m.name)}`,
        editLabel: `Edit ${capitalize(m.name)}`,

        ...m,
    };
}
//#endregion

export const entity = mapValues(metaConfig, makeFullMeta);
