import type { SetOptional } from "type-fest";
import { type SimpleModalProps, DeleteModal } from "../components/modal/DeleteModal.tsx";
import { type EntityType, entity } from "./entity.ts";

interface ADeleteButtonProps extends SetOptional<Omit<SimpleModalProps, "modalAction" | "children">, "title"> {
    deleteFn: () => Promise<unknown>;
    entityType: EntityType;
}

/** A simple delete button with a confirmation modal for entities. */
export function ADeleteButton({ deleteFn, entityType, ...props }: ADeleteButtonProps) {
    const { name } = entity[entityType];
    return (
        <DeleteModal
            title={`Delete ${name}`}
            modalAction={async () => {
                await deleteFn();
            }}
            inTable
            {...props}
        >
            Are you sure you want to delete this {name}?
        </DeleteModal>
    );
}
