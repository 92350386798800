import FF from "../../components/forms/FormField.module.css";
import TF from "../../components/forms/TextField.module.css";

import { Input, type InputProps, TextArea, type TextAreaProps } from "react-aria-components";
import { type BaseProps, mcn } from "../../utils/baseProps.ts";
import { cn } from "../../utils/classNames.ts";

export function ATextArea(props: TextAreaProps & BaseProps) {
    return <TextArea rows={5} {...mcn(cn(FF.FormFieldInput, TF.TextArea), props)} />;
}

export function AInput(props: InputProps & BaseProps) {
    return <Input {...mcn(cn(FF.FormFieldInput, TF.Input), props)} />;
}
