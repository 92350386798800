import { WModal } from "../../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../../modules/api/useStandardMutation.ts";
import { metalOsQueryAtom } from "../../../modules/metal/metalOsQuery.ts";
import { useSuspenseQueryAtom } from "../../../utils/query/useSuspenseQueryAtom.ts";
import { ADeleteButton } from "../../ADeleteButton.tsx";
import { AdminList } from "../../AdminList.tsx";
import { AdminTable } from "../../AdminTable.tsx";
import { AEditButton } from "../specs/AEditButton.tsx";
import { osDeleteMutation, osTypeDeleteMutation } from "../specs/metalOsMutation.ts";
import { osFields, osTypeFields } from "./osFields.tsx";
import { OsItemModal, OsTypeItemModal } from "./OsItemModal.tsx";

export function OsView() {
    return (
        <>
            <OsTable />
            <OsTypeTable />
        </>
    );
}

function OsTable() {
    const { os } = useSuspenseQueryAtom(metalOsQueryAtom);
    const osDelete = useStandardMutation(osDeleteMutation);

    return (
        <AdminList entityType="os" addModal={<OsItemModal />}>
            <AdminTable
                items={os}
                fields={osFields}
                getId={(e) => e.os_id}
                actionButtons={(item) => (
                    <>
                        <WModal button={<AEditButton />}>
                            <OsItemModal item={item} />
                        </WModal>
                        <ADeleteButton entityType="os" deleteFn={async () => await osDelete.mutateAsync(item)} />
                    </>
                )}
            />
        </AdminList>
    );
}

function OsTypeTable() {
    const { os_types } = useSuspenseQueryAtom(metalOsQueryAtom);
    const osTypeDelete = useStandardMutation(osTypeDeleteMutation);

    return (
        <AdminList entityType="os_type" addModal={<OsTypeItemModal />}>
            <AdminTable
                items={os_types}
                fields={osTypeFields}
                getId={(e) => e.os_type_id}
                actionButtons={(item) => (
                    <>
                        <WModal button={<AEditButton />}>
                            <OsTypeItemModal item={item} />
                        </WModal>
                        <ADeleteButton
                            entityType="os_type"
                            deleteFn={async () => await osTypeDelete.mutateAsync(item)}
                        />
                    </>
                )}
            />
        </AdminList>
    );
}
