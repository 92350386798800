import type { ReactElement, ReactNode } from "react";
import { WModalButton } from "../components/button/WToolButton.tsx";
import { WModal } from "../components/modal/WModal.tsx";
import { AdminTitle } from "./AdminTitle.tsx";
import { type EntityType, entity } from "./entity.ts";

export function AdminList({
    addModal,
    children,
    entityType,
}: {
    children: ReactNode;
    entityType: EntityType;
    addModal?: ReactElement;
}) {
    const m = entity[entityType];
    return (
        <>
            <AdminTitle title={m.viewTitle}>
                {addModal && (
                    <WModal button={<WModalButton label="New" color="primary" size="bar" variant="basic" />}>
                        {addModal}
                    </WModal>
                )}
            </AdminTitle>
            {children}
            {addModal && (
                <div className="p-2">
                    <WModal
                        button={
                            <WModalButton
                                className="w-full"
                                label={m.addLabel}
                                color="primary"
                                size="lg"
                                variant="dashed"
                                icon="jp-icon-add"
                            />
                        }
                    >
                        {addModal}
                    </WModal>
                </div>
            )}
        </>
    );
}
