import { useFormState, type FieldValues } from "react-hook-form";
import { WSubmitButton, type WSubmitButtonProps } from "../../components/button/WButton.tsx";
import { WHookForm, type WHookFormProps } from "../../components/forms/WHookForm.tsx";
import { WModalContentSimple, WModalFooter, type WModalFooterProps } from "../../components/modal/WModal.tsx";
import { entity, type EntityType } from "../entity.ts";

interface FormParams {
    entityType: EntityType;
    isUpdate: boolean;
}

function formProps({ entityType, isUpdate }: FormParams) {
    const e = entity[entityType];
    return {
        button: {
            label: isUpdate ? "Edit" : "Add",
        },
        modal: {
            title: isUpdate ? e?.editLabel : e?.addLabel,
        },
    };
}

function WFormSubmitButton(props: WSubmitButtonProps) {
    const { isSubmitting } = useFormState();
    return <WSubmitButton isLoading={isSubmitting} {...props} />;
}

export interface WAdminModalFormProps<T extends FieldValues>
    extends Omit<WHookFormProps<T>, "title">,
        FormParams,
        Pick<WModalFooterProps, "footerNotice"> {}

export function WAdminModalCrudForm<T extends FieldValues>({
    children,
    onSubmit,
    footerNotice,
    entityType,
    isUpdate,
    ...props
}: WAdminModalFormProps<T>) {
    const p = formProps({ entityType, isUpdate });

    return (
        <WModalContentSimple {...p.modal}>
            {(close) => (
                <WHookForm
                    onSubmit={async (v) => {
                        await onSubmit(v);

                        // NB: This will not be executed if onSubmit throws
                        close();
                    }}
                    {...props}
                >
                    {/* NB: Assumes children will have their own padding */}
                    {children}
                    <WModalFooter footerNotice={footerNotice}>
                        <WFormSubmitButton {...p.button} />
                    </WModalFooter>
                </WHookForm>
            )}
        </WModalContentSimple>
    );
}
