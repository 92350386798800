import type {
    MetalOsCreate,
    MetalOsTypeCreate,
    MetalOsTypeUpdate,
    MetalOsUpdate,
} from "@warrenio/api-spec/spec.oats.gen";
import { queryKey } from "../../../modules/metal/metalOsQuery.ts";
import { jsonEncodedBody } from "../../../utils/fetchClient.ts";
import { adminMutation } from "../../../utils/query/adminMutation.ts";

//#region Operating System mutations

export const osCreateMutation = adminMutation((apiClient) => ({
    entity: "os",
    operation: "create",
    requestFn: async ({ body }: { body: MetalOsCreate }) =>
        await apiClient.POST("/admin/metal/os", { ...jsonEncodedBody, body }),
    invalidateQueryKey: queryKey,
}));

export const osUpdateMutation = adminMutation((apiClient) => ({
    entity: "os",
    operation: "update",
    requestFn: async ({ body, os_id }: { body: MetalOsUpdate; os_id: string }) =>
        await apiClient.PATCH("/admin/metal/os/{os_id}", { ...jsonEncodedBody, body, params: { path: { os_id } } }),
    invalidateQueryKey: queryKey,
}));

export const osDeleteMutation = adminMutation((apiClient) => ({
    entity: "os",
    operation: "delete",
    requestFn: async ({ os_id }: { os_id: string }) =>
        await apiClient.DELETE("/admin/metal/os/{os_id}", { params: { path: { os_id } } }),
    invalidateQueryKey: queryKey,
}));

//#endregion

//#region Operating System Type mutations
export const osTypeCreateMutation = adminMutation((apiClient) => ({
    entity: "os_type",
    operation: "create",
    requestFn: async ({ body }: { body: MetalOsTypeCreate }) =>
        await apiClient.POST("/admin/metal/os_types", { ...jsonEncodedBody, body }),
    invalidateQueryKey: queryKey,
}));

export const osTypeUpdateMutation = adminMutation((apiClient) => ({
    entity: "os_type",
    operation: "update",
    requestFn: async ({ body, os_type_id }: { body: MetalOsTypeUpdate; os_type_id: string }) =>
        await apiClient.PATCH("/admin/metal/os_types/{os_type_id}", {
            ...jsonEncodedBody,
            body,
            params: { path: { os_type_id } },
        }),
    invalidateQueryKey: queryKey,
}));

export const osTypeDeleteMutation = adminMutation((apiClient) => ({
    entity: "os_type",
    operation: "delete",
    requestFn: async ({ os_type_id }: { os_type_id: string }) =>
        await apiClient.DELETE("/admin/metal/os_types/{os_type_id}", { params: { path: { os_type_id } } }),
    invalidateQueryKey: queryKey,
}));
//#endregion
