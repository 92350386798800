import { ClipBoardTooltip } from "../../components/ClipBoardTooltip.tsx";
import { type FormFieldProps, FormField } from "../../components/forms/FormField.tsx";

export function UuidField({ value, ...props }: { value: string | undefined } & FormFieldProps) {
    if (!value) {
        return null;
    }
    return (
        <FormField wide label="UUID" {...props}>
            <ClipBoardTooltip>{value}</ClipBoardTooltip>
        </FormField>
    );
}
